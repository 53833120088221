.swiper-nav {
  display: flex;
  margin: 8px -8px;
  overflow-y: hidden;

  .nav-prev {
    display: block;
    &:active {
      transform: translateX(-4px);
    }
  }
  .nav-next {
    display: block;
    &:active {
      transform: translateX(4px);
    }
  }

  .nav-prev,
  .nav-next {
    font-size: 4.8rem;
    user-select: none;
    font-family: "Caudex", serif;
    cursor: pointer;
    width: 54px;
    height: 54px;
    min-width: 54px;
    min-height: 54px;
    line-height: 52px;

    display: flex;
    text-align: center;
    justify-content: center;
    margin: 8px;
    color: black;
    border: 1px solid black;
    transition-duration: 0.1s;
    -webkit-tap-highlight-color: transparent !important;

    &:after {
      display: none;
    }
  }

  &.variant-white {
    .nav-prev,
    .nav-next {
      color: white;
      border: 1px solid white;
    }
  }

  &.variant-black {
    .nav-prev,
    .nav-next {
      color: black;
      border: 1px solid black;
    }
  }

  &.variant-bisquit {
    .nav-prev,
    .nav-next {
      color: #be9a65;
      border: 1px solid #be9a65;
    }
  }

  .swiper-notification {
    display: none;
  }
}
