@import "./../../../../global_scss/swiper/swiper-pagination";
.hotel-page {
  .RezerwujSection {
    &.last-section {
      margin-bottom: 64px;
    }
    .content {
      p {
        margin-bottom: 48px;
      }
      button {
        margin-top: 32px;
        margin-bottom: 32px;
      }
    }

    .slider {
      display: flex;
      align-items: center;

      .swiper {
        padding-bottom: 56px;
        @media only screen and (max-width: 1200px) {
          padding-bottom: 40px;
        }
        img {
          width: 100%;
        }

        @include swiperBullets;
      }
    }
  }
}
