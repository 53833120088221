.restauracja-page {
  .MenuSection {
    .other-info {
      padding-top: 64px;
    }
    .items-list {
      padding-top: 64px;
      padding-bottom: 64px;
      @media only screen and (max-width: 900px) {
        padding-top: 0px;
        padding-bottom: 64px;
      }
      ul {
        padding-inline-start: 0px;
      }
    }

    .food-menu {
      padding-top: 32px;
      .pre-menu {
        display: flex;
        width: 100%;
        @media only screen and (max-width: 900px) {
          flex-direction: column;
        }
        .pre-menu-item {
          width: 100%;
          &.right {
            text-align: right;
            @media only screen and (max-width: 900px) {
              text-align: left;
            }
          }
        }
        .pretitle {
          cursor: pointer;
          display: inline-flex;
          @media only screen and (max-width: 900px) {
            letter-spacing: 1px;
          }
          &.active {
            text-decoration: underline;
          }
        }
      }
      .main-menu {
        display: flex;
        padding-top: 48px;
        padding-bottom: 16px;
        @media only screen and (max-width: 900px) {
          flex-wrap: wrap;
          padding-top: 16px;
          padding-bottom: 0px;
        }
        .subtitle {
          margin: auto;
          display: inline-flex;
          cursor: pointer;
          color: #be9a65;
          @media only screen and (max-width: 900px) {
            min-width: 100%;
            justify-content: right;
            padding: 4px 0px 4px 0px;
            font-size: 2.4rem;
          }
          &.active {
            color: black;
          }
          &:first-child {
            margin-left: 0px;
          }
          &:last-child {
            margin-right: 0px;
          }
        }
      }
    }
  }
}
