li.ListItem {
  display: flex;
  padding-top: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid black;
  @media only screen and (max-width: 900px) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    padding-top: 16px;
    padding-bottom: 12px;
  }
  p {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 1.8rem;
    @media only screen and (max-width: 900px) {
      line-height: 1.5;
    }
    &.item-title {
      font-size: 2.1rem;
    }
  }
  .content {
    padding-right: 64px;
    @media only screen and (max-width: 600px) {
      padding-right: 0px;
    }
    width: 100%;
  }
  .actions {
    width: auto;
    text-align: right;
    b {
      white-space: nowrap;
      font-weight: 500;
      cursor: pointer;
    }
  }
}
