.button {
  background: transparent;
  border: 1px solid black;
  letter-spacing: 2px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 1.7;
  padding: 20px 24px 18px 24px;
  text-align: center;
  min-width: 256px;
  cursor: pointer;
  @media only screen and (max-width: 1200px) {
    min-width: initial;
    padding: 12px 24px 12px 24px;
    letter-spacing: 1.5px;
    font-size: 1.8rem;
  }
  &:hover {
    background: black;
    color: white;
  }
}
