.browar-page {
  .LaznieSection {
    margin-bottom: 128px;

    .mt-128 {
      margin-top: 128px;
    }
    .mt-64 {
      margin-top: 64px;
    }
    .mt-48 {
      margin-top: 48px;
    }
  }
}
