.MuiPaper-root {
  border-radius: 0px !important;
  // margin-top: 42px;
  .MuiList-root {
    .MuiListItem-button {
      font-size: 1.4rem !important;
    }
  }
}

.contact-page {
  .accept-and-send {
    margin-top: -17px;
    position: relative;
    .MuiSvgIcon-root {
      color: black;
    }
    .error-msg {
      color: #f44336;
      font-size: 1.2rem;
      font-weight: 500;
      position: absolute;
      top: -8px;
      margin-left: 22px;
      letter-spacing: -0.5px;
    }
    button {
      width: 100%;
    }
  }
  .contact-form-frame {
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: black;
  }
  .MuiInput-underline:before .MuiSvgIcon-root {
    // width: 1.1em;
    // height: 1.1em;
  }

  .MuiFormControlLabel-label {
    font-size: 1.6rem;
    line-height: 1.25;
  }

  .MuiCheckbox-root,
  .MuiCheckbox-colorSecondary.Mui-checked:hover {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .MuiTouchRipple-root {
    display: none;
  }
  .ContactFormSection {
    background: #f7e9d5;
    display: block;
    .MuiCheckbox-root,
    .MuiSelect-select {
      font-size: 1.8rem;
      line-height: 1.25;
    }
    .MuiSelect-select:focus {
      background-color: rgba(0, 0, 0, 0) !important;
    }

    .MuiFormControl-root {
      color: black;
      width: 100%;
      margin-top: 16px;
      margin-bottom: 16px;
      label {
        font-size: 1.8rem;
      }

      textarea,
      input {
        font-size: 1.8rem;
        line-height: 1.25;
      }
      .MuiFormLabel-root.Mui-focused {
        color: black !important;
      }
      .MuiInput-underline:after {
        border-bottom-color: black !important;
      }
      .MuiInput-underline:before {
        border-bottom-color: black !important;
      }
    }
  }
}
