.restauracja-page {
  .SkladnikiSection {
    .content {
      margin-top: 96px;
      .mt-64 {
        margin-top: 128px;
      }
      .mt-48 {
        margin-top: 48px;
      }
    }
  }
}
