.logo-btn {
  position: absolute;
  z-index: 997;
  left: calc(64px + 64px);
  top: 64px;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1600px) {
    left: 64px;
    top: 48px;
  }
  @media only screen and (max-width: 1200px) {
    left: 32px;
  }
  @media only screen and (max-width: 900px) {
    top: 24px;
  }
  @media only screen and (max-width: 600px) {
    top: 28px;
    left: 16px;
    img {
      width: 200px;
    }
  }
  @media only screen and (max-width: 500px) {
    top: 30px;
    img {
      width: 160px;
    }
  }
  @media only screen and (max-width: 350px) {
    top: 34px;
    img {
      width: 130px;
    }
  }
}
