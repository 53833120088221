.spa-page {
  .IntroSection {
    .content {
      margin-top: 96px;
      margin-bottom: 96px;
      .gen-text {
        img {
          width: 100%;
        }
      }
      .mt-64 {
        margin-top: 128px;
      }
      .mt-48 {
        margin-top: 48px;
      }
    }
  }
}
