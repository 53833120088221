@mixin swiperBullets {
  .swiper-pagination {
    text-align: left;
    margin-right: -4px;
    margin-left: -4px;

    .swiper-pagination-bullet {
      border: 1px solid #be9a65;
      height: 16px;
      width: 16px;
      opacity: 1;
      background: rgba(255, 255, 255, 0);

      @media only screen and (max-width: 1200px) {
        height: 12px;
        width: 12px;
      }

      &.swiper-pagination-bullet-active {
        background: #be9a65;
      }
    }
  }
}