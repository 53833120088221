.prefooter {
  a {
    color: black;
  }

  .no-decoration {
    text-decoration: none !important;
  }

  .prefooter-section {
    padding-bottom: 64px;
    .montserrat {
      font-size: 1.8rem;
    }

    .prefooter-block {
      margin-top: 32px;
      margin-bottom: 48px;
      @media only screen and (max-width: 1200px) {
        margin-top: 24px;
        margin-bottom: 32px;
      }
      @media only screen and (max-width: 900px) {
        margin-top: 16px;
        margin-bottom: 16px;
      }
      .logo {
        height: 100%;
        align-items: flex-end;
        display: flex;
        padding-bottom: 16px;
      }
    }
  }
}
