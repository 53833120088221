.lang-switch {
  &.lang-btns {
    margin-right: 15vw;
    @media only screen and (max-width: 1600px) {
      margin-right: 8vw;
    }
    @media only screen and (max-width: 1200px) {
      margin-right: 64px;
    }
    @media only screen and (max-width: 900px) {
      margin-right: 32px;
    }
    @media only screen and (max-width: 600px) {
      margin-right: 16px;
    }
    button {
      font-size: 2rem;
      font-weight: 400;
      padding-left: 4px;
      padding-right: 4px;
      cursor: pointer;
      @media only screen and (max-width: 600px) {
        font-size: 1.6rem;
      }
      &.active {
        font-weight: 700;
      }
    }
  }
}
