html {
  font-size: 10px;
  @media only screen and (max-width: 900px) {
    font-size: 9px;
  }
  @media only screen and (max-width: 600px) {
    font-size: 8px;
  }
}

body {
  margin: 0;
  // font-family: "Caudex", serif;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: calc(100vh + 1px);
}

* {
  box-sizing: border-box;
}
.App {
  position: relative;
}

.justify-center {
  justify-content: center;
  text-align: center;
}
.justify-right {
  justify-content: flex-end;
  text-align: right;
  display: flex;
}
