.home-page {
  .NewsSection {
    @media only screen and (min-width: 1600px) {
      padding-top: 52px;
      .content {
        p {
          padding-top: 30px;
        }
      }
    }
    a {
      color: white;
    }
    .news {
      @media only screen and (min-width: 1600px) {
        padding-left: 0px !important;
        padding-top: 32px !important;
      }
      ul {
        list-style: none;
        font-family: "Caudex", serif;
        font-weight: 400;
        font-size: 2.1rem;
        line-height: 1.8;
        color: white;
        padding-inline-start: 0px;

        a {
          color: white;
        }

        li {
          border-bottom: 0.5px solid #f6eddf;
          margin-bottom: 24px;
          padding-bottom: 24px;
          @media only screen and (max-width: 900px) {
            margin-bottom: 8px;
            padding-bottom: 8px;
          }
        }
      }
    }
  }
}
