.fullwidth-frame {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  transition-duration: 0.2s;

  &.py-128 {
    padding-top: 128px;
    padding-bottom: 128px;
  }

  &.py-96 {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  &.py-64 {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  &.py-32 {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  &.py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  &.px-128 {
    padding-right: 128px;
    padding-left: 128px;
    @media only screen and (max-width: 1600px) {
      padding-right: 96px;
      padding-left: 96px;
    }
  }

  &.px-96 {
    padding-right: 96px;
    padding-left: 96px;
    @media only screen and (max-width: 1600px) {
      padding-right: 64px;
      padding-left: 64px;
    }
  }

  &.px-64 {
    padding-right: 64px;
    padding-left: 64px;
    @media only screen and (max-width: 1600px) {
      padding-right: 32px;
      padding-left: 32px;
    }
  }

  &.px-32 {
    padding-right: 32px;
    padding-left: 32px;
  }

  &.px-0 {
    padding-right: 0px;
    padding-left: 0px;
  }
}
