.bisquit-frame {
  background: #f6eddf;
  width: calc(100% - 128px);
  margin-left: 64px;
  padding: 64px;
  @media only screen and (max-width: 1600px) {
    width: 100%;
    margin-left: 0px;
  }

  &.padding-256 {
    padding: 256px 64px;
    @media only screen and (max-width: 1600px) {
      padding: 128px 0px;
    }
  }

  &.padding-192 {
    padding: 192px 64px;
    @media only screen and (max-width: 1600px) {
      padding: 96px 0px;
    }
  }

  &.padding-128 {
    padding: 128px 64px;
    @media only screen and (max-width: 1600px) {
      padding: 64px 0px;
    }
  }
  &.padding-96 {
    padding: 96px 64px;
    @media only screen and (max-width: 1600px) {
      padding: 96px 0px;
    }
  }
  &.padding-64 {
    padding: 64px 64px;
    @media only screen and (max-width: 1600px) {
      padding: 64px 0px;
    }
  }
  &.padding-32 {
    padding: 32px 32px;
    @media only screen and (max-width: 1600px) {
      padding: 32px 0px;
    }
  }
}
