.text-block {
  .supertitle {
    font-family: "Caudex", serif;
    font-weight: 700;
    font-size: 12rem;
    line-height: 1.1;
    color: #be9a65;
    margin-top: 32px;
    margin-bottom: 64px;
    @media only screen and (max-width: 1200px) {
      font-size: 11rem;
      margin-top: 24px;
      margin-bottom: 48px;
    }
    @media only screen and (max-width: 900px) {
      font-size: 10.4rem;
      margin-top: 16px;
      margin-bottom: 32px;
    }
    @media only screen and (max-width: 600px) {
      font-size: 9.6rem;
    }
    @media only screen and (max-width: 400px) {
      font-size: 7rem;
    }
  }
  .superposttitle {
    font-family: "Caudex", serif;
    font-weight: 700;
    font-size: 9rem;
    line-height: 1.1;
    color: black;
    margin-top: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid black;

    @media only screen and (max-width: 1200px) {
      font-size: 8rem;
      margin-top: 12px;
      margin-bottom: 12px;
    }
    @media only screen and (max-width: 900px) {
      font-size: 7rem;
    }
    @media only screen and (max-width: 600px) {
      font-size: 6rem;
    }
    @media only screen and (max-width: 400px) {
      font-size: 5rem;
    }
    &.first {
      border-top: 1px solid black;
    }
    &.white {
      color: white;
    }
  }

  .title {
    font-family: "Caudex", serif;
    font-weight: 700;
    font-size: 6rem;
    line-height: 1.15;
    margin-top: 28px;
    margin-bottom: 32px;
    @media only screen and (max-width: 1200px) {
      font-size: 5.6rem;
      margin-top: 20px;
      margin-bottom: 24px;
    }
    @media only screen and (max-width: 900px) {
      font-size: 5.4rem;
      margin-top: 16px;
      margin-bottom: 24px;
    }
    @media only screen and (max-width: 600px) {
      font-size: 5rem;
    }
  }
  .pretitle {
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 1.7;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .subtitle {
    font-family: "Caudex", serif;
    font-weight: 700;
    font-size: 3rem;
    line-height: 1.25;
    margin-top: 24px;
    @media only screen and (max-width: 1200px) {
      font-size: 2.8rem;
      margin-top: 16px;
    }
  }

  p {
    font-family: "Caudex", serif;
    font-weight: 400;
    font-size: 2.1rem;
    line-height: 2;
    &.montserrat {
      font-family: "Montserrat", serif;
    }
    &.align-right {
      text-align: right;
    }
    &.align-center {
      text-align: center;
    }
  }

  &.white {
    color: white;
  }
}
