.spa-page {
  .SuperTitlesSection {
    .section-superposttitles-wrapper {
      margin-left: -8px;
      margin-right: -8px;
      width: calc(100% + 16px);
      max-width: calc(100% + 16px);
      h2 {
        margin-block-start: 32px;
        margin-block-end: 32px;
      }
    }

    .mt-64 {
      margin-top: 64px;
    }
  }
}
