@import "./../../../../global_scss/swiper/swiper-pagination";

.home-page {
  .HeroSection {
    display: block;

    @media only screen and (min-width: 1800px) {
      margin-top: -20px !important;
      margin-bottom: -56px !important;
    }
    .content {
      z-index: 2;

      @media only screen and (min-width: 1600px) {
        padding-bottom: 56px !important;
      }
      p {
        padding-right: 64px;
        margin-bottom: 96px;
        @media only screen and (max-width: 600px) {
          padding-right: 0px;
          margin-bottom: 64px;
        }
      }
    }

    .slider {
      display: flex;
      align-items: flex-end;
      @media only screen and (max-width: 1800px) {
        align-items: center;
      }
      @media only screen and (max-width: 1200px) {
        margin-top: 64px;
      }
      .swiper {
        padding-bottom: 56px;
        @media only screen and (max-width: 1200px) {
          padding-bottom: 40px;
        }
        img {
          width: 100%;
        }

        @include swiperBullets;
        .swiper-pagination {
          text-align: right;
        }
      }
    }
  }
}
