.menu-btns {
  position: fixed;
  z-index: 999;
  right: calc(64px + 64px - 8px);
  top: 48px;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1600px) {
    right: calc(64px + 8px);
  }
  @media only screen and (max-width: 1200px) {
    right: calc(32px + 8px);
  }
  @media only screen and (max-width: 900px) {
    top: 24px;
  }
  @media only screen and (max-width: 600px) {
    right: 24px;
  }
  button {
    border: none;
    background: none;
    font-family: "Montserrat", sans-serif;
  }

  .menu-btn {
    width: 70px;
    height: 70px;
    background: none;
    @media only screen and (max-width: 1600px) {
      width: 60px;
      height: 60px;
    }
    @media only screen and (max-width: 1200px) {
      width: 50px;
      height: 50px;
    }
    @media only screen and (max-width: 900px) {
      width: 40px;
      height: 40px;
    }
    img {
      width: 70px;
      height: auto;
      cursor: pointer;
      @media only screen and (max-width: 1600px) {
        width: 60px;
      }
      @media only screen and (max-width: 1200px) {
        width: 50px;
      }
      @media only screen and (max-width: 900px) {
        width: 40px;
      }
      &.close {
        width: 50px;
        margin-left: 8px;
        margin-top: 8px;
        @media only screen and (max-width: 1600px) {
          width: 45px;
        }
        @media only screen and (max-width: 1200px) {
          width: 40px;
        }
        @media only screen and (max-width: 900px) {
          width: 30px;
          margin-top: 0px;
        }
      }
    }
    &.status-open {
      .open {
        display: none;
      }
    }
    &.status-close {
      .close {
        display: none;
      }
    }
  }
}
