.MenuOverlayFooter {
  margin-top: 48px;
  @media only screen and (max-width: 900px) {
    display: none;
  }
  .montserrat {
    font-size: 1.8rem;
  }
  a {
    color: black;
  }
  .no-decoration {
    text-decoration: none !important;
  }
}
