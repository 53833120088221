@import "./../../../../global_scss/swiper/swiper-pagination";
.home-page {
  .BrowarSection {
    display: block;
    @media only screen and (min-width: 1600px) {
      padding-top: 128px;
      padding-bottom: 64px;
      margin-top: 18px;
      margin-bottom: -24px;
    }
    .slider {
      display: flex;
      align-items: flex-end;
      .swiper {
        padding-bottom: 56px;
        @media only screen and (max-width: 1200px) {
          padding-bottom: 40px;
        }
        @media only screen and (max-width: 1200px) {
          align-items: center;
          margin-top: 64px;
        }
        img {
          width: 100%;
        }

        @include swiperBullets;
      }
    }

    .content {
      .title {
        @media only screen and (min-width: 1600px) {
          font-size: 5.8rem;
        }
      }
      p {
        padding-bottom: 24px;
      }
      .flex {
        display: flex;
        // @media only screen and (min-width: 1740px) {
        //   display: block;
        // }
        // @media only screen and (max-width: 900px) {
        //   display: block;
        // }
        a {
          margin-top: 8px;
          margin-bottom: 8px;
          &:first-of-type {
            margin-right: 24px;
          }
        }
        button {
          min-width: 0px;

          @media only screen and (min-width: 1740px) {
            width: 100%;
          }

          @media only screen and (max-width: 900px) {
            width: 100%;
          }
        }
      }
    }
  }
}
