@import "./../../../../global_scss/swiper/swiper-pagination";
.browar-page {
  .HeroSection {
    display: block;
    margin-top: 96px;
    img {
      max-width: 90%;
    }
    .content {
      z-index: 2;

      p {
        padding-right: 64px;
        margin-bottom: 96px;
        @media only screen and (max-width: 600px) {
          padding-right: 0px;
          margin-bottom: 64px;
        }
      }
    }
  }
}
