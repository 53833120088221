@import "./../../../../global_scss/swiper/swiper-pagination";
.cepr-page {
  .AtrakcjeSection {
    .mt-64 {
      margin-top: 64px;
    }
    .section-content {
      display: flex;
      .text-block {
        display: flex;
        flex-direction: column;
        .description {
          margin-top: auto;
        }
      }
    }

    .img {
      img {
        width: 100%;
      }
    }

    .loadMore {
      margin-bottom: 128px;
      button {
        width: 100%;
      }
    }

    .options {
      margin-top: 64px;
      ul {
        padding-inline-start: 0px;
      }
    }
  }
}
