.home-page {
  .CategorySection {
    width: 100%;
    overflow-x: hidden;

    .img-block {
      height: 800px;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      align-items: center;
      justify-content: center;
      display: flex;
      @media only screen and (max-width: 1600px) {
        height: 620px;
      }
      @media only screen and (max-width: 1200px) {
        height: 480px;
      }
      @media only screen and (max-width: 900px) {
        height: 320px;
      }
      .button {
        margin-bottom: 24px;
        filter: invert(1);
      }
    }
  }
}
