.footer {
  background: white;

  .small {
    font-size: 1.4rem;
    color: #656565;
    @media only screen and (max-width: 900px) {
      text-align: center !important;
      &.align-right {
        margin-top: -8px;
      }
    }
    a {
      color: black;
      text-decoration: none;
    }
  }
}
