@import "./../../../../global_scss/swiper/swiper-pagination";
.eventy-page {
  .fullWidthImg {
    width: 100%;
    height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .EventySection {
    .mt-64 {
      margin-top: 64px;
    }
    .section-content {
      display: flex;
      .text-block {
        display: flex;
        flex-direction: column;
        .description {
          margin-top: auto;
        }
      }
      p {
        margin-top: 32px;
        margin-bottom: 32px;
      }
      img {
        margin-top: 32px;
        margin-bottom: 32px;
        width: 100%;
      }
    }

    .loadMore {
      margin-bottom: 128px;
      button {
        width: 100%;
      }
    }

    .options {
      margin-top: 32px;
      ul {
        padding-inline-start: 0px;
      }
    }
  }
}
