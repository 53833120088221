.home-page {
  .ObserwujSection {
    @media only screen and (min-width: 1600px) {
      margin-top: -26px;
    }
    .swiper-nav {
      display: flex;
      justify-content: right;
      margin-top: 32px;
      @media only screen and (max-width: 900px) {
        margin-top: 0px;
      }
      span {
        color: #be9a65;
        font-family: "Caudex", serif;
        font-weight: 700;
        font-size: 3.4rem;
        line-height: 1.15;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #be9a65;
        width: 55px;
        height: 55px;
        margin-left: 8px;
      }
    }
    .slider {
      margin-top: 32px;
      img {
        width: 100%;
      }
    }
  }
}
