@import "./../../../../global_scss/swiper/swiper-pagination";
.restauracja-page{
  .HeroSection {
    display: block;
    margin-top: 96px;
    
    .gallery {
      align-items: flex-end;
      img {
        width: 100%;
        margin-top: 32px;
        margin-bottom: 32px;
      }
    }
    .content {
      z-index: 2;
      
      p {
        padding-right: 64px;
        margin-bottom: 0px;
        @media only screen and (max-width: 600px) {
          padding-right: 0px;
        }
      }
    }
  }
}