.home-page {
  .OffersSection {
    @media only screen and (min-width: 1600px) {
      padding-top: 64px;
      margin-bottom: 128px;
      .section-description {
        margin-top: -28px;
      }
    }
    @media only screen and (max-width: 900px) {
      margin-top: 32px;
    }
    @media only screen and (max-width: 1600px) {
      br {
        display: none;
      }
    }
    a {
      color: black;
    }
    .offer-items {
      @media only screen and (max-width: 900px) {
        align-items: center;
      }
    }
    .offer-item {
      margin-top: 80px;
      box-sizing: border-box;
      @media only screen and (max-width: 1200px) {
        margin-top: 64px;
      }
      @media only screen and (max-width: 900px) {
        margin-top: 48px;
      }
      img {
        width: 100%;
        @media only screen and (max-width: 900px) {
          max-width: 420px;
        }
      }
      h3 {
        min-height: 72px;
        margin-top: 24px;
        margin-bottom: 8px;
        @media only screen and (max-width: 900px) {
          min-height: initial;
          margin-top: 16px;
        }
      }
      p {
        @media only screen and (max-width: 1600px) {
          margin-top: 16px;
        }
        @media only screen and (max-width: 1200px) {
          margin-top: 12px;
        }
        @media only screen and (max-width: 900px) {
          margin-top: 8px;
        }
      }
    }
  }
}
