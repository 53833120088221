.three-stars-wrapper {
  position: relative;
  height: 0px;

  .three-stars {
    display: flex;
    position: absolute;
    top: -16px;
    @media only screen and (max-width: 900px) {
      height: 10px !important;
      top: -12px;
    }
  }
}
