.cepr-page {
  .DlaczegoSection {
    margin-bottom: 128px;

    h2 {
      margin-top: 16px;
      margin-bottom: 32px;
    }

    .mt-64 {
      margin-top: 64px;
    }

    .section-superposttitles-wrapper {
      margin-left: -8px;
      margin-right: -8px;
      width: calc(100% + 16px);
      max-width: calc(100% + 16px);

      h2 {
        margin-block-start: 48px;
        margin-block-end: 48px;
      }
    }
  }
}
