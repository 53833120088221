.MapSection {
  padding-bottom: 64px;
  margin-top: -20px;
  @media only screen and (min-width: 1600px) {
    margin-bottom: -8px;
  }
  .mapmock {
    background: bisque;
  }
  .mapmock,
  #map {
    height: 500px;
    filter: grayscale(1);
    @media only screen and (max-width: 900px) {
      height: 420px;
    }
  }
}
