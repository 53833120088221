@import "./../../../../global_scss/swiper/swiper-pagination";
.hotel-page {
  .HeroSection {
    display: block;
    margin-top: 96px;

    .content {
      z-index: 2;

      p {
        padding-right: 64px;
        margin-bottom: 96px;
        @media only screen and (max-width: 600px) {
          padding-right: 0px;
          margin-bottom: 64px;
        }
      }
    }
  }
}
