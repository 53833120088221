.ToggleWPoblizuItem {
  display: block;
  padding-bottom: 24px;
  border-bottom: 1px solid black;
  margin-bottom: 24px;

  .header {
    user-select: none;
    cursor: pointer;
    &:hover {
      p.toggle-title {
        letter-spacing: 0.2px;
      }
    }
    .text-block {
      display: flex;
      align-items: center;
      p.toggle-title {
        transition-duration: 0.3s;
      }
      .sign {
        width: 128px;
        text-align: center;
        font-size: 4rem;
        font-family: "Caudex", serif;
        font-weight: bold;
      }

      .distance {
        margin-left: auto;
        p {
          display: flex;
          font-size: 1.8rem;
          span {
            min-width: 128px;
            display: block;
            text-align: right;
          }
        }
      }
    }
  }

  .collapsible {
    padding-left: 128px;
    .description {
      max-width: 900px;
      padding: 16px 0px;
    }
    .other-info {
      padding: 16px 0px;
      p {
        display: flex;
        b {
          display: block;
          min-width: 256px;
        }
      }
    }
  }
}
