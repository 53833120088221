.menu-overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #f6eddf;
  z-index: 998;
  transition-duration: 1.5s;
  padding-top: 16px;
  border-right: 1px solid black;
  overflow-y: auto;
  &.status-open {
    left: 1px;
  }

  &.status-close {
    left: -100%;
  }

  .constainer {
    &.isHidden {
      display: none;
    }
  }
  .menu-btn {
    position: initial;
  }

  .rezerwuj {
    @media only screen and (max-width: 900px) {
      margin-top: 16px;
    }
  }

  nav {
    padding-top: 64px;
    padding-bottom: 64px;
    @media only screen and (max-width: 900px) {
      padding-top: 32px;
      padding-bottom: 32px;
    }
    ul {
      list-style: none;
      padding-inline-start: 0px;
      text-align: center;
      li {
        font-size: 2.8rem;
        font-weight: bold;
        line-height: 1;
        padding: 10px;
        margin: 10px;
        @media only screen and (max-width: 900px) {
          font-size: 2rem;
          padding: 6px;
          margin: 6px;
        }
        a {
          text-decoration: none;
          color: black;
        }
      }
    }
  }
}
