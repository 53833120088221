.sticky-booking-btn {
  position: fixed;
  z-index: 997;
  right: calc(64px + 64px - 8px);
  top: 50vh;
  transform: rotate(90deg);
  @media only screen and (max-width: 1920px) {
    right: calc(64px + 64px);
  }

  @media only screen and (max-width: 1600px) {
    right: 48px;
  }

  @media only screen and (max-width: 1200px) {
    right: 32px;
  }
  @media only screen and (max-width: 900px) {
    display: none;
  }
  button {
    position: absolute;
    transform: translateX(-50%) scaleY(-100%) scaleX(-100%);
  }
}
