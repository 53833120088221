.hotel-page {
  .ApartamentySection {
    .other-info {
      padding-top: 64px;
    }
    .items-list {
      padding-top: 64px;
      padding-bottom: 64px;
      ul {
        padding-inline-start: 0px;
      }
    }
  }
}
