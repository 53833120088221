.Specifications {
  padding-top: 64px;
  padding-bottom: 64px;
  .SpecificationItem {
    font-size: 1.8rem;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    letter-spacing: 0.15em;
    line-height: 1.75;
    white-space: nowrap;
	
    b {
      font-weight: 600;
    }
  }
}
