.restauracja-page {
  .SzefSection {
    padding-top: 64px;
    padding-bottom: 64px;
    @media only screen and (max-width: 600px) {
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .content {
      p {
        padding-top: 32px;
        @media only screen and (max-width: 1600px) {
          padding-top: 0px;
        }
      }
    }
    .atractions {
      ul {
        list-style: none;
        font-family: "Caudex", serif;
        font-weight: 400;
        font-size: 2.1rem;
        line-height: 2;
        color: white;
        padding-inline-start: 0px;

        a {
          color: white;
        }

        li {
          border-bottom: 0.5px solid #f6eddf;
          margin-bottom: 24px;
          padding-bottom: 24px;
          @media only screen and (max-width: 1600px) {
            margin-bottom: 20px;
            padding-bottom: 20px;
          }
          @media only screen and (max-width: 900px) {
            margin-bottom: 8px;
            padding-bottom: 8px;
          }
        }
      }
    }
  }
}
