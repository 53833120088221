@import "./../../../../global_scss/swiper/swiper-pagination";
.home-page {
  .HotelFrame {
    @media only screen and (max-width: 1200px) {
      padding-top: 0px !important;
      br {
        display: none;
      }
    }
  }
  .HotelSection {
    @media only screen and (min-width: 1600px) {
      margin-top: -12px;
    }
    .content {
      p {
        margin-bottom: 48px;
      }
      .flex {
        .button {
          margin-right: 16px;
        }
      }
    }

    .slider {
      display: flex;
      align-items: flex-end;
      @media only screen and (max-width: 1200px) {
        align-items: center;
        margin-top: 64px;
      }
      .swiper {
        padding-bottom: 56px;
        @media only screen and (max-width: 1200px) {
          padding-bottom: 40px;
        }
        img {
          width: 100%;
        }

        @include swiperBullets;
      }
    }

    .reversed {
      margin-top: 300px;
      margin-bottom: 48px;
      @media only screen and (max-width: 1200px) {
        flex-direction: column-reverse;
        margin-top: 128px;
      }
      .content {
        p {
          padding-bottom: 32px;
        }

        @media only screen and (min-width: 1600px) {
          padding-left: 0px !important;
        }
      }
    }
  }
}
